import {handleSentryError} from './sentryErrors'

export async function asyncThrottle(fns, options) {
  if (!fns?.length) {
    return []
  }
  const defaultOptions = {limit: 20, abortController: new AbortController(), checkDelay: 20}
  const {limit, abortController, checkDelay} = {...defaultOptions, ...options}
  const {signal} = abortController

  let cnt = 0
  const ready = () => {
    if (cnt < limit) {
      return cnt
    }
    return new Promise(resolve => {
      const iv = setInterval(() => {
        if (cnt < limit) {
          resolve(cnt)
          clearInterval(iv)
        }
      }, checkDelay)
    })
  }

  const results = []
  for (let i = 0; i < fns.length; i++) {
    if (signal.aborted) {
      throw new Error(signal.reason)
    }
    await ready()
    cnt++
    const result = fns[i]()
      .then(res => {
        cnt--
        return res
      })
      .catch(err => {
        handleSentryError(err)
        cnt--
        return null
      })
    results.push(result)
  }
  return results
}

export async function asyncThrottleIterator(iterator, fn, options) {
  const defaultOptions = {limit: 20, abortController: new AbortController(), checkDelay: 20}
  const {limit, abortController, checkDelay} = {...defaultOptions, ...options}
  const {signal} = abortController

  let cnt = 0
  const ready = () => {
    if (cnt < limit) {
      return cnt
    }
    return new Promise(resolve => {
      const iv = setInterval(() => {
        if (cnt < limit) {
          resolve(cnt)
          clearInterval(iv)
        }
      }, checkDelay)
    })
  }

  const results = []
  const promises = []

  async function run(iterator, ctx) {
    const item = await iterator.next()
    if (item.done) {
      ctx.done = true
    }
    const result = fn(item.value)
      .then(res => {
        cnt--
        return res
      })
      .catch(err => {
        handleSentryError(err)
        cnt--
        return null
      })
    results.push(result)
  }

  const ctx = {done: false}
  while (true) {
    if (ctx.done) {
      break
    }
    if (signal.aborted) {
      throw new Error(signal.reason)
    }
    await ready()
    cnt++
    try {
      promises.push(run(iterator, ctx))
    } catch (err) {
      console.error(err)
    }
  }
  await Promise.allSettled(promises)
  return results
}
