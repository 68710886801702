import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {DialogContent} from '@mui/material'
import {queries, queryProfile} from '@monsantoit/profile-client'

import {getBearerToken} from '../utilities/initializeProfile'
import {isProd} from '../utilities/serviceBindings'
import DialogContentContainer from './styled/DialogContentContainer'
import UploaderOrthoMosaicUploadingPane from './UploaderOrthoMosaicUploadingPane'
import UploaderOrthoMosaicFileSelector from './UploaderOrthoMosaicFileSelector'
import UploaderOrthoMosaicMetadataForm from './UploaderOrthoMosaicMetadataForm'
import UploaderDialogFooterOrthoMosaic from './UploaderDialogFooterOrthoMosaic'
import CardGenericError from './CardGenericError'
import {handleSentryError} from '../utilities/sentryErrors'
import {fetchOrthoSdk} from '../sdk/orthoSdk'

const FormContainer = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
`

function UploaderOrthoMosaic(props) {
  const {metadata, isHyperspec} = props
  const [uploader, setUploader] = useState(null)
  const [criticalError, setCriticalError] = useState('')

  // @ts-ignore
  const workflowStage = useSelector(state => state.imageUploader.uploadWorkflowStage)

  async function initializeUploader(src) {
    const {
      getCurrentUser: {id: username},
    } = await queryProfile(queries.currentUser())

    const UploaderModule = await fetchOrthoSdk()

    const source = src?.s3Uri
      ? src
      : src instanceof FileList
      ? {files: Array.from(src)}
      : {file: src}
    const uploaderInstance = await UploaderModule.Uploader({
      authorization: async () => `Bearer ${await getBearerToken()}`,
      environment: isProd ? 'prod' : 'nonprod',
      source,
      username,
    })

    setUploader(uploaderInstance)

    return uploaderInstance
  }

  // Initialize the uploader when an s3 source is provided
  useEffect(() => {
    if (metadata && Object.keys(metadata).length === 2) {
      initializeUploader(metadata).catch(err => {
        const details = 'Failed to initialize uploader'
        handleSentryError(err, details)
        setCriticalError(details)
      })
    }
  }, [metadata])

  return (
    <>
      <DialogContentContainer>
        <DialogContent>
          <FormContainer>
            {!criticalError ? (
              <>
                {workflowStage === 0 && (
                  <UploaderOrthoMosaicFileSelector
                    initializeUploader={initializeUploader}
                    setCriticalError={setCriticalError}
                    isHyperspec={isHyperspec}
                  />
                )}
                {workflowStage === 1 && (
                  <UploaderOrthoMosaicMetadataForm
                    metadata={uploader?.metadata}
                    setCriticalError={setCriticalError}
                    isHyperspec={isHyperspec}
                  />
                )}
                {workflowStage === 2 && (
                  <UploaderOrthoMosaicUploadingPane
                    uploader={uploader}
                    setCriticalError={setCriticalError}
                  />
                )}
              </>
            ) : (
              <CardGenericError
                title="Unable to process orthomosaic scene"
                description={criticalError}
                caption="If this error persists, please contact Location360 Support"
              />
            )}
          </FormContainer>
        </DialogContent>
      </DialogContentContainer>
      <UploaderDialogFooterOrthoMosaic />
    </>
  )
}

export default UploaderOrthoMosaic
