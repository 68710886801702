import React, {useEffect, useState} from 'react'
import {FormHelperText} from '@mui/material'
import {Star as StarIcon} from '@mui/icons-material/'
import {handleSentryError} from '../utilities/sentryErrors'
import useToastNotification from '../hooks/useToastNotification'
import {getCurrenUserGroups} from '../utilities/profile'
import {GroupOption} from './SelfService'
import {BorderedSection} from './BorderedSection'
import {EntitlementField} from './EntitlementField'

export function GroupEntitlementFields({onClose}) {
  const [groupOptions, setGroupOptions] = useState<GroupOption[]>([])
  const {errorNotification} = useToastNotification()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCurrenUserGroups()
      .then(groups => {
        setGroupOptions(groups.map(g => ({...g, hasGroup: true})) ?? [])
      })
      .catch(err => {
        const details = 'Failed to load user groups.'
        errorNotification(details)
        handleSentryError(err, details)
        onClose()
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <BorderedSection title="Group Entitlements" style={{marginTop: '20px'}}>
        <EntitlementField name="admin" label="Admin *" options={groupOptions} loading={loading} />
        <EntitlementField name="delete" label="Delete" options={groupOptions} loading={loading} />
        <EntitlementField name="publish" label="Publish" options={groupOptions} loading={loading} />
        <EntitlementField
          name="download"
          label="Download"
          options={groupOptions}
          loading={loading}
        />
      </BorderedSection>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <StarIcon style={{color: 'rgba(0,0,0,0.6)'}} fontSize="small" />
        &ensp;<FormHelperText>you are a member of this group</FormHelperText>
      </div>
    </>
  )
}
