import React, {useContext, useEffect, useState, useReducer} from 'react'
import {useParams, useLocation, useNavigate} from 'react-router-dom'
import type {Item, ItemListing} from '@bayer-int/imagine-sdk-browser'
import {handleSentryError} from '../utilities/sentryErrors'
import BasicSearchSectionExactMatch from '../components/BasicSearchSectionExactMatch'
import GalleryViewErrorCard from '../components/GalleryViewErrorCard'
import LoadingSpinner from '../components/LoadingSpinner'
import GalleryViewGrid from '../components/GalleryViewGrid'
import GallerySummaryBasicSearch from '../components/GallerySummaryBasicSearch'
import GallerySummaryBasicSearchAccordion from '../components/GallerySummaryBasicSearchAccordion'
import {AlbumListProvider} from '../context/albumListContext'
import {GalleryPageProvider} from '../context/galleryPageContext'
import {GalleryBasicSearchProvider} from '../context/galleryBasicSearchContext'
import {imagineServiceContext} from '../context/imagineServiceContext'
import useGalleryPageState from '../hooks/useGalleryPageState'
import useRetrieveBasicSearchHistogram from '../hooks/useRetrieveBasicSearchHistogramData'
import CommonLayout from '../layouts/CommonLayout'
import GalleryPageLayout from '../layouts/GalleryPageLayout'
import {decodeAdvancedSearchQuery} from '../utilities/advancedSearchQueryString'
import mapSearchParametersToFetchItemsOptions from '../utilities/advancedSearchParameters'
import {getPreferencesValue, setPreferencesValue} from '../utilities/userPreferences'
import {isUUID} from '../utilities/uuid'

const GalleryPageBasicSearch = () => {
  const searchTermDecoded = decodeURIComponent(useParams().searchTerm)
  const searchTerm = isUUID(searchTermDecoded) ? `"${searchTermDecoded}"` : searchTermDecoded
  const [itemListing, setItemListing] = useState(null)
  const navigate = useNavigate()

  const {
    loading: globalLoading,
    error: globalLoadingError,
    imagineSdk,
  } = useContext(imagineServiceContext)

  const {
    error,
    items,
    initialLoading,
    loading,
    numberResultingItems,
    removeItem,
    resetSearch,
    exactMatch,
    exactMatchByProperty,
    setExactMatch,
    setExactMatchByProperty,
    setServerError,
    setNumberItemsPossible,
    next,
    hasMore,
  } = useGalleryPageState(itemListing)

  useExactKeywordMatch({keyword: searchTerm, setExactMatch: setExactMatchByProperty})
  useItemCount({itemListing, setNumberItemsPossible})

  const {SEARCH_RESULT_SUMMARY_LIMIT, newHistogramElement, searchTermsChanged} =
    useRetrieveBasicSearchHistogram()

  const [condensedSearch, setCondensedSearch] = useState(false)
  const [fullSearchTerms, setFullSearchTerms] = useState(null)
  const [showHistogram, setShowHistogram] = useState(false)
  const [loadingPercent, setLoadingPercent] = useState(0)

  const searchParams = new URLSearchParams(useLocation().search)
  searchParams.delete('page')
  const search = searchParams.toString()

  const [state, dispatch] = useReducer(reducer, initialHistogramState)
  const {
    previousSearchTerms,
    projectsHistogram,
    collectionsHistogram,
    tagsHistogram,
    numberItems,
    exceedsSizeThreshold,
    loadingHistogram,
  } = state

  const queryParamSearchTerms = fullSearchTerms

  const canSearch = searchTerm && !(globalLoadingError || globalLoading)

  function setHistogram({
    projectsHistogram,
    collectionsHistogram,
    tagsHistogram,
    numberItems,
    loadingHistogram,
  }) {
    dispatch({
      type: 'set-histogram',
      projectsHistogram,
      collectionsHistogram,
      tagsHistogram,
      numberItems,
      loadingHistogram,
    })
  }

  function setSearchTerm(term: string) {
    dispatch({type: 'set-search-term', term})
  }

  function setPreviousSearchTerms(terms: string) {
    dispatch({type: 'set-previous-terms', terms})
  }

  useEffect(() => {
    if (globalLoadingError) {
      setServerError()
    }
  }, [globalLoadingError])

  useEffect(() => {
    resetSearch()
  }, [])

  useEffect(() => {
    if (!canSearch || !searchTerm) {
      return
    }
    // Sometimes the UUID is not an item id which causes this to throw.
    // We do not log the error since we do not have validation to assure it is an UUID
    updateExactMatch().catch()

    async function updateExactMatch() {
      if (isUUID(searchTerm)) {
        const exactMatch = await imagineSdk.fetchItem({id: searchTerm})
        if (exactMatch) setExactMatch(exactMatch)
      }
    }
  }, [canSearch, searchTerm])

  useEffect(() => {
    const searchTerms = decodeAdvancedSearchQuery(search)
    const sdkCompatibleSearchTerms = mapSearchParametersToFetchItemsOptions(searchTerms)
    if (Object.keys(sdkCompatibleSearchTerms).length > 0) setCondensedSearch(true)

    setFullSearchTerms({
      ...sdkCompatibleSearchTerms,
      keywords: searchTerm,
    })
  }, [search])

  useEffect(() => {
    setSearchTerm(searchTerm)
  }, [searchTerm])

  useEffect(() => {
    if (!canSearch) {
      return
    }
    const itemListing = imagineSdk.fetchItems({
      keywords: searchTerm,
      limit: Infinity,
    })
    setItemListing(itemListing)
  }, [canSearch, searchTerm])

  useEffect(() => {
    if (itemListing && next) next('down')
  }, [itemListing])

  useEffect(() => {
    async function getHistogramPreference() {
      const preference = (await getPreferencesValue('show-histogram-preference')) ?? false

      setShowHistogram(preference)
    }

    getHistogramPreference()
  }, [])

  useEffect(() => {
    async function retrieveSearchSummaries() {
      let numberItemsScanned = 0
      let numberItemsSearchResult = numberResultingItems ?? 1
      let numberItemsBaselineSearchResult = 0

      const currSearchProjectsHistogram = {}
      const currSearchCollectionsHistogram = {}
      const currSearchTagsHistogram = {}

      setHistogram({
        projectsHistogram: currSearchProjectsHistogram,
        collectionsHistogram: currSearchCollectionsHistogram,
        tagsHistogram: currSearchTagsHistogram,
        numberItems:
          numberItemsBaselineSearchResult > 0
            ? numberItemsBaselineSearchResult
            : numberItemsSearchResult,
        loadingHistogram: true,
      })

      const enhancedSearchTermsActive =
        (fullSearchTerms.projects?.length ?? 0) > 0 ||
        (fullSearchTerms.collections?.length ?? 0) > 0 ||
        fullSearchTerms.keywords.length > 1

      // Retrieve the search based solely on the search bar term for a compare
      if (enhancedSearchTermsActive) {
        const compareItemElements = await imagineSdk.fetchItems({
          keywords: searchTerm,
          limit: SEARCH_RESULT_SUMMARY_LIMIT,
        })

        numberItemsBaselineSearchResult = await compareItemElements.count

        for await (let itemElement of compareItemElements) {
          const project = await itemElement.project()
          const collection = await itemElement.collection()
          const tags = itemElement.properties?.['tags:tags'] ?? []

          projectsHistogram[project.id] = projectsHistogram[project.id]?.numBaselineResultingItems
            ? {
                ...projectsHistogram[project.id],
                numBaselineResultingItems:
                  projectsHistogram[project.id].numBaselineResultingItems + 1,
              }
            : newHistogramElement({baseline: true})

          collectionsHistogram[collection.id] = collectionsHistogram[collection.id]
            ?.numBaselineResultingItems
            ? {
                ...collectionsHistogram[collection.id],
                numBaselineResultingItems:
                  collectionsHistogram[collection.id].numBaselineResultingItems + 1,
              }
            : newHistogramElement({baseline: true})

          for (const tag of tags) {
            tagsHistogram[tag] = tagsHistogram[tag]?.numBaselineResultingItems
              ? {
                  ...tagsHistogram[tag],
                  numBaselineResultingItems: tagsHistogram[tag].numBaselineResultingItems + 1,
                }
              : newHistogramElement({baseline: true})
          }

          numberItemsScanned++
          setLoadingPercent(parseInt((100 * numberItemsScanned) / numberItemsBaselineSearchResult))
        }
      }

      const itemElements = await imagineSdk.fetchItems({
        ...queryParamSearchTerms,
        limit: numberResultingItems,
      })

      for await (let itemElement of itemElements) {
        const project = await itemElement.project()

        const projectElementPresent =
          Object.keys(currSearchProjectsHistogram).indexOf(project.id) !== -1

        currSearchProjectsHistogram[project.id] = projectElementPresent
          ? {
              ...currSearchProjectsHistogram[project.id],
              numResultingItems: currSearchProjectsHistogram[project.id].numResultingItems + 1,
            }
          : newHistogramElement()

        const collection = await itemElement.collection()

        const collectionElementPresent =
          Object.keys(currSearchCollectionsHistogram).indexOf(collection.id) !== -1

        currSearchCollectionsHistogram[collection.id] = collectionElementPresent
          ? {
              ...currSearchCollectionsHistogram[collection.id],
              numResultingItems:
                currSearchCollectionsHistogram[collection.id].numResultingItems + 1,
            }
          : newHistogramElement()

        const tags = itemElement.properties?.['tags:tags'] ?? []

        for (const tag of tags) {
          const tagElementPresent = Object.keys(currSearchTagsHistogram).indexOf(tag) !== -1

          currSearchTagsHistogram[tag] = tagElementPresent
            ? {
                ...currSearchTagsHistogram[tag],
                numResultingItems: currSearchTagsHistogram[tag].numResultingItems + 1,
              }
            : newHistogramElement()
        }

        numberItemsScanned++

        if (showHistogram) {
          setLoadingPercent(
            parseInt(
              (100 * numberItemsScanned) /
                (numberItemsBaselineSearchResult
                  ? numberItemsBaselineSearchResult
                  : numberResultingItems)
            )
          )
        }
      }

      setHistogram({
        projectsHistogram: currSearchProjectsHistogram,
        collectionsHistogram: currSearchCollectionsHistogram,
        tagsHistogram: currSearchTagsHistogram,
        numberItems:
          numberItemsBaselineSearchResult > 0
            ? numberItemsBaselineSearchResult
            : numberItemsSearchResult,
        loadingHistogram: false,
      })

      setLoadingPercent(0)
    }

    const sdkReady = !(globalLoadingError || globalLoading)

    if (
      sdkReady &&
      numberResultingItems &&
      queryParamSearchTerms &&
      searchTermsChanged(previousSearchTerms, queryParamSearchTerms) &&
      showHistogram
    ) {
      if (numberResultingItems >= SEARCH_RESULT_SUMMARY_LIMIT) {
        dispatch({type: 'set-exceeds-threshold'})
        setPreviousSearchTerms(queryParamSearchTerms)
      } else {
        retrieveSearchSummaries()
        setPreviousSearchTerms(queryParamSearchTerms)
      }
    }
  }, [
    numberResultingItems,
    globalLoading,
    globalLoadingError,
    queryParamSearchTerms,
    showHistogram,
  ])

  const onHistogramToggle = () => {
    const updatedPreference = !showHistogram
    setShowHistogram(updatedPreference)
    setPreferencesValue('show-histogram-preference', updatedPreference)
  }

  if (!searchTerm) {
    navigate('/', {replace: true})
  }

  if (error)
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <GalleryViewErrorCard {...error} />
        </GalleryPageLayout>
      </CommonLayout>
    )

  if (globalLoading || initialLoading)
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <LoadingSpinner message="Retrieving results..." />
        </GalleryPageLayout>
      </CommonLayout>
    )

  const summary = (
    <GalleryBasicSearchProvider
      queryParamSearchTerms={fullSearchTerms}
      searchTerm={searchTerm}
      numberResultingItems={numberResultingItems}
    >
      <GallerySummaryBasicSearch
        condensedSearch={condensedSearch}
        searchTerm={searchTerm}
        numberItems={numberResultingItems}
        showHistogram={showHistogram}
        onHistogramToggle={onHistogramToggle}
      />
      {showHistogram && (
        <GallerySummaryBasicSearchAccordion
          numberResultingItems={numberResultingItems}
          searchTerm={searchTerm}
          accordionIndex={1}
          projectsHistogram={projectsHistogram}
          collectionsHistogram={collectionsHistogram}
          tagsHistogram={tagsHistogram}
          numberItems={numberItems}
          exceedsSizeThreshold={exceedsSizeThreshold}
          loadingHistogram={loadingHistogram}
          loadingPct={loadingPercent}
        />
      )}
      {/* <GalleryBasicSearchMenuRefinement accordionIndex={2} /> */}
    </GalleryBasicSearchProvider>
  )

  const exactMatchItem = exactMatch ? exactMatch : exactMatchByProperty
  const exactMatchSection = exactMatchItem && (
    <BasicSearchSectionExactMatch exactMatch={exactMatchItem} />
  )

  return (
    <CommonLayout>
      <AlbumListProvider>
        <GalleryPageProvider
          type="search"
          loading={loading}
          items={items}
          removeItem={removeItem}
          next={next}
          hasMore={hasMore}
        >
          <GalleryPageLayout summaryComponent={summary} specialContent={exactMatchSection}>
            <GalleryViewGrid fullSearchTerms={fullSearchTerms.keywords} />
          </GalleryPageLayout>
        </GalleryPageProvider>
      </AlbumListProvider>
    </CommonLayout>
  )
}

const initialHistogramState = {
  queryParamSearchTerms: {},
  searchTerm: '',
  projectsHistogram: {},
  collectionsHistogram: {},
  tagsHistogram: {},

  numberItems: 0,

  useCompareHistogram: false,
  loadingHistogram: true,
  exceedsSizeThreshold: false,
  previousSearchTerms: null,
}

function reducer(state, action) {
  switch (action.type) {
    case 'set-histogram':
      return {
        ...state,
        projectsHistogram: action.projectsHistogram,
        collectionsHistogram: action.collectionsHistogram,
        tagsHistogram: action.tagsHistogram,

        loadingHistogram: action.loadingHistogram,

        numberItems: action.numberItems,
      }
    case 'set-exceeds-threshold':
      return {...state, exceedsSizeThreshold: true}
    case 'set-search-term':
      return {...state, searchTerm: action.term}
    case 'set-previous-terms':
      return {...state, previousSearchTerms: action.terms}

    default:
      throw new Error(`unnown action ${action.type} called in useRetrieveBasicSearchHistogram`)
  }
}

function useItemCount({
  itemListing,
  setNumberItemsPossible,
}: {
  itemListing: ItemListing
  setNumberItemsPossible: (cnt: number) => void
}) {
  useEffect(() => {
    if (!itemListing) {
      return
    }
    updateItemCount().catch(err => {
      const additionalErrorDetails = 'Error in attempting to retrieve count.'
      handleSentryError(err, additionalErrorDetails)
    })

    async function updateItemCount() {
      const itemCount = await itemListing.count()
      setNumberItemsPossible(itemCount)
    }
  }, [itemListing])
}

function findMatchingValue(obj: any, value: string): boolean {
  if (!obj) {
    return false
  }

  if (Array.isArray(obj)) {
    return obj.some(v => findMatchingValue(v, value))
  }

  if (typeof obj === 'object') {
    return Object.values(obj).some(v => findMatchingValue(v, value))
  }

  return obj === value
}

function isExactItemMatch(item: Item, keyword: string) {
  return findMatchingValue(item.properties, keyword)
}

async function findExactMatches(itemListing: ItemListing, searchTerm: string): Promise<Item[]> {
  for await (const item of itemListing) {
    if (isExactItemMatch(item, searchTerm)) {
      return [item]
    }
  }

  return []
}

const MAX_EXACT_MATCH_CHECKS = 50

export function useExactKeywordMatch({
  keyword,
  setExactMatch,
}: {
  keyword: string
  setExactMatch: (item: Item) => void
}) {
  const {
    loading: globalLoading,
    error: globalLoadingError,
    imagineSdk,
  } = useContext(imagineServiceContext)

  const canSearch = !(globalLoadingError || globalLoading)

  useEffect(() => {
    if (!canSearch || !keyword) {
      return
    }
    updateExactMatch().catch(err => handleSentryError(err, 'Error processing for exact matches.'))

    async function updateExactMatch() {
      const itemListing = imagineSdk.fetchItems({
        keywords: keyword,
        limit: MAX_EXACT_MATCH_CHECKS,
      })

      const exactMatches = await findExactMatches(itemListing, keyword)
      if (exactMatches?.length) {
        setExactMatch(exactMatches[0])
      }
    }
  }, [canSearch, keyword])

  return {}
}

export default GalleryPageBasicSearch
