import React, {createRef, useEffect, useReducer} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {useDispatch} from 'react-redux'
import {CircularProgress, Typography} from '@mui/material'

import {addAvailableWorkflowStages} from '../actions/imageUploaderAction'
import useOrthoMosaicValidateTrigger from '../hooks/useOrthoMosaicValidateTrigger'
import useToastNotification from '../hooks/useToastNotification'
import {nonDropdownProperties, dropdownProperties} from '../utilities/orthomosaicFormElements'

import DropArea from './styled/DropArea'
import DropInputLabel from './styled/DropInputLabel'
import UploaderOrthoMosaicMetadataFieldBoolean from './UploaderOrthoMosaicMetadataFieldBoolean'
import UploaderOrthoMosaicMetadataFieldDate from './UploaderOrthoMosaicMetadataFieldDate'
import UploaderOrthoMosaicMetadataFieldMultipleAutocomplete from './UploaderOrthoMosaicMetadataFieldMultipleAutocomplete'
import UploaderOrthoMosaicMetadataFieldSelect from './UploaderOrthoMosaicMetadataFieldSelect'
import UploaderOrthoMosaicMetadataFieldSelectMultiple from './UploaderOrthoMosaicMetadataFieldSelectMultiple'
import UploaderOrthoMosaicMetadataFieldString from './UploaderOrthoMosaicMetadataFieldString'
import UploaderOrthoMosaicMetadataFieldInteger from './UploaderOrthoMosaicMetadataFieldInteger'
import {handleSentryError} from '../utilities/sentryErrors'

const FormContainer = styled('div')`
  width: 830px;
  height: 500px;

  padding: 10px 30px;
  display: grid;
  grid-row-gap: 20px;

  overflow-y: scroll;
  margin: 20px auto 0 auto;
`

const defaultState = {
  loading: true,
  values: {},
  options: {},
  optionDescriptions: {},
  errors: {},
  refs: {},
  disabled: {
    growthStage: 'Please identify crop type',
    traitRecipe: 'Please identify both crop type and growth stage',
    featureNames: 'Please identify feature type',
  },
  traitRecipeInfo: [],
}

function reducer(state, action) {
  switch (action.type) {
    case 'set-initial-properties':
      return {
        ...state,
        refs: action.refs,
        values: action.values,
        options: action.options,
        optionDescriptions: action.optionDescriptions,
        loading: false,
        errors: {},
      }
    case 'update-field-value':
      return {
        ...state,
        values: {
          ...state.values,
          [action.key]: action.value,
        },
      }
    case 'update-trait-recipe-info':
      return {
        ...state,
        traitRecipeInfo: action.traitRecipeInfo,
      }
    case 'remove-disabled-message':
      return {...state, disabled: {...state.disabled, [action.key]: null}}
    case 'update-field-error-indicators':
      return {
        ...state,
        errors: action.errors,
      }
    case 'update-sensor-band-choices':
      return {
        ...state,
        options: {
          ...state.options,
          sensorBands: action.sensorBandOptions ?? [],
        },
        values: {
          ...state.values,
          sensorBands: state.values.sensorBands ?? [],
        },
      }

    case 'update-option-fields':
      return {
        ...state,
        options: {
          ...state.options,
          ...action.elements,
        },
      }

    default:
      throw new Error(
        `Unknown reducer call "${action.type}" encountered in UploaderMissionPackageMetadaForm component`
      )
  }
}

function generateInitialState(selectedProperties) {
  const values = {}
  const options = {}
  const refs = {}

  dropdownProperties.forEach(property => {
    values[property.name] = property.type.endsWith('array-string') ? [] : ''
    if (selectedProperties[property.name]) values[property.name] = selectedProperties[property.name]
    options[property.name] = []
  })

  nonDropdownProperties.forEach(({name, type, minimum}) => {
    if (type === 'integer') {
      values[name] = selectedProperties[name] ?? minimum
    } else if (type === 'string') {
      values[name] = selectedProperties[name] ?? ''
    } else if (type === 'array-string') {
      values[name] = (selectedProperties[name] && selectedProperties[name].split(',')) || []
    } else if (type === 'date') {
      values[name] = selectedProperties[name]
    } else if (type === 'boolean') {
      values[name] = selectedProperties[name] ?? false
    } else {
      throw new Error(
        'Unsupported data type provided by nonDropdownProperties array in functon generateInitialState'
      )
    }
  })

  Object.keys(values).forEach(key => {
    refs[key] = createRef()
  })

  return {values, options, refs}
}

/*
 *
 * All of the metadata form elements for the mosiac form
 *
 * Source of truth: https://github.platforms.engineering/location-360/orthomosaic-uploader/blob/main/src/Metadata.ts#L345-L365
 *
 * See: interface MetadataProperties
 *
 *
 */
function UploaderOrthoMosaicMetadataForm(props) {
  const {metadata, setCriticalError, isHyperspec} = props

  const centralDispatch = useDispatch()

  const {errorNotification, warningNotification, successNotification} = useToastNotification()
  const [orthoMosaicValidating, setOrthoMosaicValidating] = useOrthoMosaicValidateTrigger()
  const [state, dispatch] = useReducer(reducer, defaultState)

  const setFieldValue = ({key, value}) => {
    if (!key) return
    // We must use React State in order to allow form components to see change in values
    dispatch({
      type: 'update-field-value',
      key,
      value,
    })
  }
  const updateDropdownChoices = elements =>
    dispatch({
      type: 'update-option-fields',
      elements,
    })

  /** Set up initial values and dropdown options */
  useEffect(() => {
    async function initialLoad() {
      const selectedProperties = {
        ...metadata.getProperties(),
        ...(isHyperspec ? {originator: 'Hyperspec'} : {}),
      }

      const {options, values, refs} = generateInitialState(selectedProperties)

      const newOptions = {
        crop: metadata.crops(),
        featureType: metadata.featureTypes(),
        sensor: metadata.sensors(),
        workflow: metadata.workflows(),
        region: metadata.regions(),
        originator: metadata.originators(),
        traitRecipes: metadata.traitRecipes({}),
      }
      const newOptionResults = await Promise.allSettled(Object.values(newOptions))
      for (const [index, key] of Object.keys(newOptions).entries()) {
        const {reason: error, value} = newOptionResults[index]
        if (error) {
          newOptions[key] = []
          const details = `Could not obtain form values for ${key}`
          handleSentryError(error, details)
          setCriticalError(details)
        } else {
          newOptions[key] = value
        }
      }
      const optionDescriptions = {
        traitRecipe: {},
      }
      try {
        for (const recipe of newOptions.traitRecipes) {
          // eslint-disable-next-line no-await-in-loop
          const {traitModules} = await metadata.traitRecipeInfo(recipe)
          optionDescriptions.traitRecipe[recipe] = traitModules?.join(', ') ?? 'No analytics'
        }
      } catch (e) {
        const additionalErrorDetails = 'Could not obtain form values for trait recipe.'
        handleSentryError(e, additionalErrorDetails)
        setCriticalError(e.message)
      }

      dispatch({
        type: 'set-initial-properties',
        options: {...options, ...newOptions},
        optionDescriptions,
        values,
        refs,
      })
    }

    if (metadata) initialLoad()
  }, [metadata])

  // Load growth stage options on crop load
  useEffect(() => {
    async function loadGrowthStages() {
      const growthStage = await metadata.growthStagesForCrop(state.values.crop).catch(e => {
        const additionalErrorDetails = 'There was an error retrieving the growth stages.'
        handleSentryError(e, additionalErrorDetails)
      })
      dispatch({type: 'remove-disabled-message', key: 'growthStage'})

      setFieldValue({
        name: 'growthStage',
        value: '',
      })
      setFieldValue({
        name: 'traitRecipe',
        value: '',
      })
      updateDropdownChoices({growthStage})
    }

    if (state.values.crop) loadGrowthStages()
  }, [state.values.crop])

  // Load possible featureNames on population of feature type
  useEffect(() => {
    function loadFeatureNames() {
      dispatch({type: 'remove-disabled-message', key: 'featureNames'})
      metadata.searchFeatureNames(state.values.featureType).then(featureNames => {
        setFieldValue({
          name: 'featureNames',
          value: [],
        })
        updateDropdownChoices({featureNames})
      })
    }

    // Not implemented or clear yet. Skipping until next SDK release. https://github.platforms.engineering/location-360/orthomosaic-uploader/blob/main/src/Metadata.ts#L136
    if (state.values.featureType) loadFeatureNames()
  }, [state.values.featureType, state.values.region])

  // Load possible sensor bands on population of sensor, and clear selected bands
  useEffect(() => {
    async function loadSensorBands() {
      const sensorBandOptions = await metadata.bandsForSensor(state.values.sensor).catch(e => {
        const additionalErrorDetails = 'There was an error retrieving the bands for a sensor.'
        handleSentryError(e, additionalErrorDetails)
      })

      dispatch({type: 'update-sensor-band-choices', sensorBandOptions})
    }

    if (state.values.sensor) loadSensorBands()
  }, [state.values.sensor])

  // Load possible trait recipes on selection of growth stage
  useEffect(() => {
    async function loadtraitRecipes() {
      const traitRecipe = await metadata.traitRecipes(state.value).catch(e => {
        const additionalErrorDetails = 'There was an error retrieving a trait recipe.'
        handleSentryError(e, additionalErrorDetails)
      })
      dispatch({type: 'remove-disabled-message', key: 'traitRecipe'})
      setFieldValue({
        name: 'traitRecipe',
        value: '',
      })
      updateDropdownChoices({traitRecipe})
    }

    if (state.values.growthStage && state.values.crop) loadtraitRecipes()
  }, [state.values.growthStage, state.values.crop, state.values.sensor])

  // Loads trait recipe details
  useEffect(() => {
    async function loadTraitRecipeInfo() {
      const {traitModules: traitRecipeInfo} = await metadata
        .traitRecipeInfo(state.values.traitRecipe)
        .catch(e => {
          const additionalErrorDetails = 'There was an error retrieving trait recipe info.'
          handleSentryError(e, additionalErrorDetails)
        })
      dispatch({type: 'update-trait-recipe-info', traitRecipeInfo})
    }
    if (state.values.traitRecipe) {
      loadTraitRecipeInfo()
    } else {
      dispatch({type: 'update-trait-recipe-info', traitRecipeInfo: []})
    }
  }, [state.values.traitRecipe])

  useEffect(() => {
    async function loadOrganizations(featureType, featureNames) {
      updateDropdownChoices({organization: []})
      setFieldValue({key: 'organization', value: ''})

      const organization = await metadata.organizations({featureType, featureNames}).catch(e => {
        const additionalErrorDetails = 'There was an error loading organizations.'
        handleSentryError(e, additionalErrorDetails)
      })

      updateDropdownChoices({organization})
    }

    const {featureNames, featureType} = state.values

    if (featureType && featureNames?.length > 0) loadOrganizations(featureType, featureNames)
  }, [state.values.featureNames, state.values.featureType])

  // Validates on change of state of trigger state
  useEffect(() => {
    async function validate() {
      const errors = await metadata.validateProperties(state.values).catch(e => {
        const additionalErrorDetails = 'There was an error validating properties.'
        handleSentryError(e, additionalErrorDetails)
      })

      dispatch({
        errors: errors || {},
        type: 'update-field-error-indicators',
      })

      const criticalErrorKeys =
        errors &&
        Object.entries(errors)
          .filter(error => error[1].level === 'error')
          .map(i => i[0])

      if (errors && criticalErrorKeys.length > 0) {
        const scrollToFirstError = () => {
          for (const key of criticalErrorKeys) {
            if (state.refs[key].current) {
              state.refs[key].current.scrollIntoView()
              return
            }
          }
        }
        scrollToFirstError()

        errorNotification(
          'Validation failed, update the affected fields, and validate again to progress'
        )
        console.warn('Errors were found in the validation. ', state.values)
      } else if (errors) {
        warningNotification('Validation passed, but warnings are present')

        metadata.setProperties(state.values)

        centralDispatch(addAvailableWorkflowStages([1, 2]))
      } else {
        successNotification('Validation passed')

        metadata.setProperties(state.values)
        centralDispatch(addAvailableWorkflowStages([1, 2]))
      }

      setOrthoMosaicValidating(false)
    }

    if (orthoMosaicValidating) validate()
  }, [orthoMosaicValidating])

  // Returns the elements at the last point of the file selector for consistency
  if (state.loading) {
    return (
      <DropArea>
        <DropInputLabel>
          <div className="icon-container">
            <CircularProgress />
          </div>
          <Typography use="subtitle1" style={{cursor: 'pointer'}}>
            Uploader initializing
          </Typography>
        </DropInputLabel>
      </DropArea>
    )
  }

  return (
    <FormContainer $numberFields={nonDropdownProperties.length + dropdownProperties.length}>
      <UploaderOrthoMosaicMetadataFieldSelect
        dropdownOptions={state.options.workflow}
        label="Workflow"
        helperText="The processing workflow the image should be sent to"
        reactRef={state.refs.workflow}
        value={state.values.workflow}
        setValue={value =>
          setFieldValue({
            key: 'workflow',
            value,
          })
        }
        errorLevel={state.errors.workflow?.level}
        errorMessage={state.errors.workflow?.message}
      />
      <UploaderOrthoMosaicMetadataFieldDate
        label="Collection Date"
        name="collectionDate"
        helperText="the date the flight occured"
        reactRef={state.refs.collectionDate}
        value={state.values.collectionDate}
        setValue={value => {
          setFieldValue({
            key: 'collectionDate',
            value,
          })
        }}
        errorLevel={state.errors.collectionDate?.level}
        errorMessage={state.errors.collectionDate?.message}
      />
      <UploaderOrthoMosaicMetadataFieldBoolean
        label="Preprocessed Orthomosaic"
        name="preprocessed"
        helperText="Whether the image has been fully orthorectified and quality controlled"
        reactRef={state.refs.preprocessed}
        value={state.values.preprocessed}
        setValue={value => {
          setFieldValue({
            key: 'preprocessed',
            value,
          })
        }}
        errorLevel={state.errors.preprocessed?.level}
        errorMessage={state.errors.preprocessed?.message}
      />

      <UploaderOrthoMosaicMetadataFieldInteger
        label="Altitude (m)"
        helperText="The height above ground level the UAV was flown at"
        reactRef={state.refs.altitude}
        value={state.values.altitude}
        setValue={value => {
          setFieldValue({
            key: 'altitude',
            value,
          })
        }}
        minimum={10}
        maximum={120}
        interval={10}
        errorLevel={state.errors.altitude?.level}
        errorMessage={state.errors.altitude?.message}
      />
      <UploaderOrthoMosaicMetadataFieldInteger
        label="GCP Count"
        helperText="The number of ground control points used to orthorectify the image"
        reactRef={state.refs.gcpCount}
        value={state.values.gcpCount}
        setValue={value => {
          setFieldValue({
            key: 'gcpCount',
            value,
          })
        }}
        minimum={0}
        maximum={10}
        interval={1}
        errorLevel={state.errors.gcpCount?.level}
        errorMessage={state.errors.gcpCount?.message}
      />
      <UploaderOrthoMosaicMetadataFieldString
        label="Comments"
        helperText="Miscellaneous notes about the flight or orthomosaic data"
        reactRef={state.refs.comments}
        value={state.values.comments}
        required={false}
        setValue={value => {
          setFieldValue({
            key: 'comments',
            value,
          })
        }}
        errorLevel={state.errors.comments?.level}
        errorMessage={state.errors.comments?.message}
      />
      <UploaderOrthoMosaicMetadataFieldString
        label="Operator"
        helperText="The company-assigned user ID of the UAV pilot"
        reactRef={state.refs.operator}
        value={state.values.operator}
        setValue={value => {
          setFieldValue({
            key: 'operator',
            value,
          })
        }}
        errorLevel={state.errors.operator?.level}
        errorMessage={state.errors.operator?.message}
      />

      <UploaderOrthoMosaicMetadataFieldSelect
        dropdownOptions={state.options.crop}
        label="Crop"
        helperText="The crop that was imaged"
        reactRef={state.refs.crop}
        value={state.values.crop}
        setValue={value =>
          setFieldValue({
            key: 'crop',
            value,
          })
        }
        errorLevel={state.errors.crop?.level}
        errorMessage={state.errors.crop?.message}
      />
      <UploaderOrthoMosaicMetadataFieldSelect
        dropdownOptions={state.options.growthStage}
        label="Growth Stage"
        helperText="The growth stage of the crop that was imaged"
        reactRef={state.refs.growthStage}
        value={state.values.growthStage}
        setValue={value =>
          setFieldValue({
            key: 'growthStage',
            value,
          })
        }
        disabledMessage={state.disabled?.growthStage}
        errorLevel={state.errors.growthStage?.level}
        errorMessage={state.errors.growthStage?.message}
      />
      <UploaderOrthoMosaicMetadataFieldSelect
        dropdownOptions={state.options.traitRecipe}
        optionDescriptions={state.optionDescriptions.traitRecipe}
        label="Trait Recipe"
        helperText="The analytical products that should be generated from this image"
        reactRef={state.refs.traitRecipe}
        value={state.values.traitRecipe}
        setValue={value =>
          setFieldValue({
            key: 'traitRecipe',
            value,
          })
        }
        disabledMessage={state.disabled?.traitRecipe}
        errorLevel={state.errors.traitRecipe?.level}
        errorMessage={state.errors.traitRecipe?.message}
      />

      <UploaderOrthoMosaicMetadataFieldSelect
        dropdownOptions={state.options.sensor}
        label="Sensor"
        helperText="The sensor used to capture the source images"
        reactRef={state.refs.sensor}
        value={state.values.sensor}
        setValue={value =>
          setFieldValue({
            key: 'sensor',
            value,
          })
        }
        errorLevel={state.errors.sensor?.level}
        errorMessage={state.errors.sensor?.message}
      />
      <UploaderOrthoMosaicMetadataFieldSelectMultiple
        dropdownOptions={state.options.sensorBands}
        label="Sensor Bands"
        helperText="The spectral bands captured by the sensor"
        reactRef={state.refs.sensorBands}
        value={state.values.sensorBands}
        setValue={value =>
          setFieldValue({
            key: 'sensorBands',
            value,
          })
        }
        errorLevel={state.errors.sensorBands?.level}
        errorMessage={state.errors.sensorBands?.message}
      />

      <UploaderOrthoMosaicMetadataFieldSelect
        dropdownOptions={state.options.region}
        label="Region"
        helperText="The region the flight occurred within"
        reactRef={state.refs.region}
        value={state.values.region}
        setValue={value =>
          setFieldValue({
            key: 'region',
            value,
          })
        }
        errorLevel={state.errors.region?.level}
        errorMessage={state.errors.region?.message}
      />
      <UploaderOrthoMosaicMetadataFieldSelect
        dropdownOptions={state.options.originator}
        label="Originator"
        helperText="The company that employs the UAV pilot"
        reactRef={state.refs.originator}
        value={state.values.originator}
        setValue={value =>
          setFieldValue({
            key: 'originator',
            value,
          })
        }
        errorLevel={state.errors.originator?.level}
        errorMessage={state.errors.originator?.message}
        disabled={isHyperspec}
      />
      <UploaderOrthoMosaicMetadataFieldSelect
        dropdownOptions={state.options.featureType}
        label="Feature Type"
        helperText="The type of land feature that was imaged"
        reactRef={state.refs.featureType}
        value={state.values.featureType}
        setValue={value => {
          ;[
            {key: 'organization', value: ''},
            {key: 'featureNames', value: []},
            {key: 'featureType', value},
          ].forEach(setFieldValue)
        }}
        errorLevel={state.errors.featureType?.level}
        errorMessage={state.errors.featureType?.message}
      />
      <UploaderOrthoMosaicMetadataFieldMultipleAutocomplete
        disabled={!state.values.featureType}
        dropdown
        dropdownOptions={state.options.featureNames}
        label="Feature Names"
        name="featureNames"
        helperText="The identifying name(s) of the land feature(s)"
        reactRef={state.refs.featureNames}
        value={state.values.featureNames}
        updateValue={value => {
          setFieldValue({
            key: 'featureNames',
            value,
          })
        }}
        retrieveFeatureNames={async startsWith =>
          metadata.searchFeatureNames(state.values.featureType, startsWith)
        }
        disabledMessage={state.disabled?.featureNames}
        errorLevel={state.errors.featureNames?.level}
        errorMessage={state.errors.featureNames?.message}
      />
      <UploaderOrthoMosaicMetadataFieldSelect
        dropdownOptions={state.options.organization}
        label="Organization"
        helperText="The organization you belong to"
        reactRef={state.refs.organization}
        value={state.values.organization}
        setValue={value =>
          setFieldValue({
            key: 'organization',
            value,
          })
        }
        errorLevel={state.errors.organization?.level}
        errorMessage={state.errors.organization?.message}
      />
    </FormContainer>
  )
}

UploaderOrthoMosaicMetadataForm.propTypes = {
  /** the metadata provided by the orthomosiac uploader sdk */
  metadata: PropTypes.shape({}).isRequired,
  /** Sets an error for the uploader workflow */
  setCriticalError: PropTypes.func.isRequired,
  isHyperspec: PropTypes.string.isRequired,
}

export default UploaderOrthoMosaicMetadataForm
