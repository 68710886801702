import React, {useContext, useEffect, useState} from 'react'

import {Dialog, DialogTitle} from '@mui/material'
import {useSelector, useDispatch} from 'react-redux'

import styled from 'styled-components'

import {closeUploader} from '../actions/imageUploaderAction'
import {imagineServiceContext} from '../context/imagineServiceContext'
import uploadWorkflows from '../utilities/uploadWorkflows'

import UploaderDialogWorkflowSelector from './UploaderDialogWorkflowSelector'
import ImagineUploaderStageBreadcrumbs from './ImagineUploaderStageBreadcrumbs'
import UploaderExperimental from './UploaderExperimental'
import UploaderImagine from './UploaderImagine'
import UploaderMissionPackage from './UploaderMissionPackage'
import UploaderOrthoMosaic from './UploaderOrthoMosaic'
import LoadingSpinner from './LoadingSpinner'
import CardGenericError from './CardGenericError'
import {uploaderContext} from '../context/uploaderContext'

const TitleSection = styled('section')`
  display: flex;
  justify-content: space-between;
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 960px;
  }
`

function DialogImagineUploader() {
  const [workflow, setWorkflow] = useState(0)
  // @ts-ignore
  const {workflowType, metadata} = useContext(uploaderContext)

  // @ts-ignore
  const open = useSelector(state => state.imageUploader.dialogOpen)
  const {loading, error} = useContext(imagineServiceContext)

  const dispatch = useDispatch()
  const handleClose = () => {
    setWorkflow(0)
    dispatch(closeUploader())
  }

  useEffect(() => {
    if (workflowType) setWorkflow(workflowType)
  }, [workflowType])

  if (!open) return <></>
  // NOTE: It should not be possible to make it to this state.
  if (loading) return <LoadingSpinner message="Please wait. Connecting to Imagine service" />
  if (error)
    return (
      <CardGenericError
        title="Unable to upload data"
        description="The Imagine UI is unable to upload data without a connection to the Imagine service"
      />
    )

  let title = uploadWorkflows[workflow]?.title
  if (title && workflow !== 0) title += ' Uploader'

  return (
    <StyledDialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="upload-dialog"
      maxWidth="md"
    >
      <TitleSection>
        <DialogTitle style={{textAlign: 'left', width: '100%'}}>{title}</DialogTitle>
      </TitleSection>

      {/* For use in all types of workflows except for the workflow selector */}
      {workflow !== 0 && <ImagineUploaderStageBreadcrumbs workflow={workflow} />}

      {/* Dialog content and actions exist in the following components */}
      {workflow === 0 && <UploaderDialogWorkflowSelector setWorkflow={setWorkflow} />}
      {workflow === 1 && <UploaderImagine />}
      {workflow === 2 && <UploaderMissionPackage />}
      {workflow === 3 && <UploaderOrthoMosaic metadata={metadata} />}
      {workflow === 4 && <UploaderExperimental />}
      {workflow === 5 && <UploaderOrthoMosaic metadata={metadata} isHyperspec />}
    </StyledDialog>
  )
}

export default DialogImagineUploader
