import {useEffect, useState} from 'react'
import useCurrentUserGroups from './useCurrentUserGroups'
import {handleSentryError} from '../utilities/sentryErrors'

function useHasGroups(groups: string | string[]) {
  const [hasGroups, setHasGroups] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()

  const {
    loading: loadingGroups,
    error: groupLoadingError,
    currentUserGroups,
  } = useCurrentUserGroups()

  useEffect(() => {
    function checkGroups() {
      if (loadingGroups) {
        return
      }

      setLoading(false)

      if (groupLoadingError) {
        handleSentryError(groupLoadingError)
        setError(`Failed to check groups. ${groupLoadingError.message}`)
        return
      }

      const groupIds = currentUserGroups.map(g => g.id)
      const hasGroups = Array.isArray(groups)
        ? groups.every(g => groupIds.includes(g))
        : groupIds.includes(groups)

      if (hasGroups) {
        setHasGroups(true)
      }
    }

    try {
      checkGroups()
    } catch (err) {
      handleSentryError(err)
      setError(`Failed to check groups. ${err.message}`)
    }
  }, [loadingGroups, groupLoadingError, currentUserGroups])

  return {hasGroups, error, loading}
}

export default useHasGroups
