import React, {useState, useMemo, useEffect, useContext} from 'react'
import {Button, FormGroup, FormControlLabel, Switch, Fab} from '@mui/material'
import {Add as AddIcon} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import * as yup from 'yup'

import styled from 'styled-components'

import loc360Logo from '../assets/loc360-logo.jpeg'
import DirectoryGridContent from '../components/DirectoryGridContent'
import DirectoryGridFilter from '../components/DirectoryGridFilter'
import CardGenericError from '../components/CardGenericError'
import LoadingSpinner from '../components/LoadingSpinner'
import ButtonCreateNewAlbum from '../components/ButtonCreateNewAlbum'
import TextIndicatorDirectoryLoadState from '../components/TextIndicatorDirectoryLoadState'
import useCollectionPin from '../hooks/useCollectionPin'
import {
  CreateExtensionFields,
  CreateCollectionFields,
  CreateProjectFields,
  requiredExtensions,
} from '../components/SelfService'
import {ExtensionFormDialog} from '../components/ExtensionFormDialog'
import {CollectionFormDialog} from '../components/CollectionFormDialog'
import {ProjectFormDialog} from '../components/ProjectFormDialog'
import type {
  Collection,
  EntitlementLevel,
  Entitlements,
  Project,
  StacExtension,
  CreateStacExtensionOptions,
} from '@bayer-int/imagine-sdk-browser'
import useToastNotification from '../hooks/useToastNotification'
import {handleSentryError} from '../utilities/sentryErrors'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {findApp, findGroup} from '../utilities/profile'
import useCurrentUserGroups from '../hooks/useCurrentUserGroups'

const MainDisplay = styled('div')`
  height: calc(95vh - 207px);
  width: 100vw;
  margin: 0 auto;

  overflow-x: hidden;
  overflow-y: scroll;

  .count-placeholder {
    height: 44px;
    width: 100%;
  }

  .notification-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filterd-projects-count {
    width: 100%;
    padding: 10px;
    min-height: 40px;
  }
`

const DirectoryFooter = styled.footer`
  display: flex;
  gap: 12px;
  background: white;
  width: 100vw;
  position: absolute;
  bottom: 0;

  padding-bottom: 10px;
  padding-left: 15px;
  margin: 0 auto;

  align-items: center;

  .toggle {
    padding: inherit 5px;
  }

  button {
    height: 50px;
  }
`

const ToggleButton = styled(Button)`
  background-color: ${props => (props.disableElevation ? '#2980b9' : '#2196f3')} !important;
  color: white !important;
`

const EXTENSION_ADMIN_PAPI_ID = 'DATA-360-LOCATION-360-DEV-TEAM'

async function createAppEntitlementOptions(entitlements: Entitlements) {
  return createEntitlementOptions(
    entitlements,
    (entitlements: Entitlements, level: EntitlementLevel) => {
      return entitlements.appsWithEntitlement(level)
    },
    findApp,
    'app'
  )
}

async function createPapiEntitlementOptions(entitlements: Entitlements) {
  return createEntitlementOptions(
    entitlements,
    (entitlements: Entitlements, level: EntitlementLevel) => {
      return entitlements.papiGroupsWithEntitlement(level)
    },
    findGroup,
    'group'
  )
}

type EntitlementOption = {
  id: string
  name: string
  description: string
}

async function createEntitlementOptions(
  entitlements: Entitlements,
  getEntitlements: (entitlements: Entitlements, level: EntitlementLevel) => string[],
  getOption: (id: string) => Promise<EntitlementOption>,
  entitlementType: 'app' | 'group',
  levels: EntitlementLevel[] = [
    'ADMIN' as EntitlementLevel,
    'DELETE' as EntitlementLevel,
    'PUBLISH' as EntitlementLevel,
    'DOWNLOAD' as EntitlementLevel,
  ],
  levelIx: number = 0,
  entitlementMap: Record<string, boolean> = {},
  entitlementOptions: Record<EntitlementLevel, EntitlementOption[]> = {
    ADMIN: [],
    DELETE: [],
    PUBLISH: [],
    DOWNLOAD: [],
  }
): Promise<Record<EntitlementLevel, EntitlementOption[]>> {
  if (levelIx >= levels.length) {
    return entitlementOptions
  }
  const level = levels[levelIx]
  const entitlementIds = getEntitlements(entitlements, level)
  const optionIds = entitlementIds.filter((id: string) => !entitlementMap[id])
  optionIds.forEach((id: string) => {
    entitlementMap[id] = true
  })
  const options = await Promise.all(
    optionIds.map(async (id: string) => {
      try {
        const option = await getOption(id)
        if (!option) {
          throw new Error(`Failed to find ${entitlementType} with id ${id}`)
        }
        return option
      } catch (err) {
        handleSentryError(err)
      } finally {
        return {
          id,
          name: id,
          description: '',
        }
      }
    })
  )
  entitlementOptions[level as string] = options
  return createEntitlementOptions(
    entitlements,
    getEntitlements,
    getOption,
    entitlementType,
    levels,
    levelIx + 1,
    entitlementMap,
    entitlementOptions
  )
}

/** Common layout for all three directory pages */
function DirectoryLayout(props) {
  const {
    filteredContent,
    contentType,
    loading,
    error,
    populating,
    numPossibleElements,
    maxCount,
    onAddData,
  } = props
  const {successNotification} = useToastNotification()
  const {errorNotification} = useToastNotification()
  const {imagineSdk} = useContext(imagineServiceContext)

  const [hideEmptyContent, setHideEmptyContent] = useState(false)
  const [userPinnedCollections, setUserPinnedCollections] = useState([])

  const {getPinnedCollections} = useCollectionPin()

  const [showCreateCollectionDialog, setShowCreateCollectionDialog] = useState(false)
  const [showCreateExtensionDialog, setShowCreateExtensionDialog] = useState(false)
  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState(false)
  const [recentCreatedExtensions, setRecentCreatedExtensions] = useState<
    CreateStacExtensionOptions[]
  >([])

  const [projectInitialiValues, setProjectInitialValues] = useState<CreateProjectFields>()
  const [collectionInitialiValues, setCollectionInitialValues] = useState<CreateCollectionFields>()
  const [extensionInitialValues, setExtensionInitialValues] = useState<CreateExtensionFields>()

  const {currentUserGroups} = useCurrentUserGroups()

  const canEditExtensions = currentUserGroups?.some(g => g.id === EXTENSION_ADMIN_PAPI_ID)

  const extensionCreateValidator = (values: CreateExtensionFields) => {
    const errors = {}

    if (!values.prefix) {
      errors['prefix'] = 'Prefix is required'
    }
    if (!values.title) {
      errors['title'] = 'Title is required'
    }
    if (!values.description) {
      errors['description'] = 'Description is required'
    }
    if (values.major === '') {
      errors['major'] = 'Major is required'
    }
    if (values.minor === '') {
      errors['minor'] = 'Minor is required'
    }
    if (values.patch === '') {
      errors['patch'] = 'Patch is required'
    }
    if (values.collectionProperties) {
      values.collectionProperties.forEach((attr, i) => {
        const attributeErrors = {}

        if (!attr.fieldName) {
          attributeErrors['fieldName'] = 'Name is required'
        }
        if (!attr.fieldType) {
          attributeErrors['fieldType'] = 'Type is required'
        }

        if (Object.keys(attributeErrors).length) {
          if (!errors['collectionProperties']) {
            errors['collectionProperties'] = {}
          }
          errors['collectionProperties'][i] = attributeErrors
        }
      })
    }

    return errors
  }

  const extensionUpdateValidator = (values: CreateExtensionFields) => {
    const errors = {}

    if (!values.prefix) {
      errors['prefix'] = 'Prefix is required'
    }
    if (!values.title) {
      errors['title'] = 'Title is required'
    }
    if (!values.description) {
      errors['description'] = 'Description is required'
    }
    if (!/^\d+$/.test(values.major)) {
      errors['major'] = 'Major must be a positive digit'
    }
    if (!/^\d+$/.test(values.minor)) {
      errors['minor'] = 'Minor must be a positive digit'
    }
    if (!/^\d+$/.test(values.patch)) {
      errors['patch'] = 'Patch must be a positive digit'
    }
    if (values.major === '') {
      errors['major'] = 'Major is required'
    }
    if (values.minor === '') {
      errors['minor'] = 'Minor is required'
    }
    if (values.patch === '') {
      errors['patch'] = 'Patch is required'
    }
    const majorIsLess = values.major < extensionInitialValues?.major
    const minorIsLess = values.minor < extensionInitialValues?.minor
    const patchIsLess = values.patch < extensionInitialValues?.patch
    const majorIsEqual = values.major === extensionInitialValues?.major
    const minorIsEqual = values.minor === extensionInitialValues?.minor
    const patchIsEqual = values.patch === extensionInitialValues?.patch
    if (
      values.major &&
      extensionInitialValues?.major &&
      values.major !== extensionInitialValues?.major
    ) {
      if (majorIsLess) {
        errors['major'] = 'Version cannot be smaller than previous version'
      }
    } else if (
      values.minor &&
      extensionInitialValues?.minor &&
      values.major !== extensionInitialValues?.major
    ) {
      if (minorIsLess && majorIsEqual) {
        errors['minor'] = 'Version cannot be smaller than previous version'
      }
    } else if (
      values.patch &&
      extensionInitialValues?.patch &&
      values.patch !== extensionInitialValues?.patch
    ) {
      if (patchIsLess && minorIsEqual && majorIsEqual) {
        errors['patch'] = 'Version cannot be smaller than previous version'
      }
    }
    if (majorIsEqual && minorIsEqual && patchIsEqual) {
      errors['major'] = 'Extension updates must increment version'
      errors['minor'] = 'Extension updates must increment version'
      errors['patch'] = 'Extension updates must increment version'
    }
    if (values.collectionProperties) {
      values.collectionProperties.forEach((attr, i) => {
        const attributeErrors = {}

        if (!attr.fieldName) {
          attributeErrors['fieldName'] = 'Name is required'
        }
        if (!attr.fieldType) {
          attributeErrors['fieldType'] = 'Type is required'
        }

        if (Object.keys(attributeErrors).length) {
          if (!errors['collectionProperties']) {
            errors['collectionProperties'] = {}
          }
          errors['collectionProperties'][i] = attributeErrors
        }
      })
    }

    return errors
  }

  const extensionValidator = extensionInitialValues
    ? extensionUpdateValidator
    : extensionCreateValidator

  const [currentCollectionEdit, setCurrentCollectionEdit] = useState<Collection>()
  const [currentProjectEdit, setCurrentProjectEdit] = useState<Project>()
  const [currentExtensionEdit, setCurrentExtensionEdit] = useState<StacExtension>()

  const mapProjectToFormFields = async (project: Project): Promise<CreateProjectFields> => {
    const entitlements = await project.entitlements()
    const papiEntitlements = await createPapiEntitlementOptions(entitlements)

    const appEntitlements = await project.entitlements()
    const appEntitlementOptions = await createAppEntitlementOptions(appEntitlements)
    const region = project.uploadBuckets.find(x => x.isDefault)?.region
    return {
      title: project.title,
      appEntitlements: {
        admin: appEntitlementOptions.ADMIN,
        delete: appEntitlementOptions.DELETE,
        publish: appEntitlementOptions.PUBLISH,
        download: appEntitlementOptions.DOWNLOAD,
      },
      admin: papiEntitlements.ADMIN,
      delete: papiEntitlements.DELETE,
      publish: papiEntitlements.PUBLISH,
      download: papiEntitlements.DOWNLOAD,
      region: region,
    }
  }

  const mapCollectionToFormFields = async (
    collection: Collection
  ): Promise<CreateCollectionFields> => {
    const entitlements = await collection.entitlements()
    const extensions = await collection.itemStacExtensions()
    const extensionFields = extensions.map(ext => ({
      label: ext.title,
      value: ext.id,
      description: ext.description,
      version: ext.version,
      disabled: requiredExtensions.includes(ext.id),
    }))
    const papiEntitlements = await createPapiEntitlementOptions(entitlements)
    const appEntitlements = await collection.entitlements()
    const appEntitlementOptions = await createAppEntitlementOptions(appEntitlements)
    return {
      name: collection.title,
      description: collection.description,
      appEntitlements: {
        admin: appEntitlementOptions.ADMIN,
        delete: appEntitlementOptions.DELETE,
        publish: appEntitlementOptions.PUBLISH,
        download: appEntitlementOptions.DOWNLOAD,
      },
      admin: papiEntitlements.ADMIN,
      delete: papiEntitlements.DELETE,
      publish: papiEntitlements.PUBLISH,
      download: papiEntitlements.DOWNLOAD,
      extensions: extensionFields,
      keywords: collection.keywords,
      license: collection.license,
      providers: collection.providers,
    }
  }

  const extensionSchema = yup
    .object({
      $id: yup.string(),
      $schema: yup.string(),
      title: yup.string(),
      description: yup.string(),
      type: yup.string(),
      properties: yup
        .object({
          properties: yup
            .object({
              properties: yup.object(),
              required: yup.array(),
              type: yup.string(),
            })
            .noUnknown(true),
        })
        .noUnknown(true),
    })
    .noUnknown(true)

  const mapExtensionToFormFields = async (
    extension: StacExtension
  ): Promise<CreateExtensionFields> => {
    const versions = extension.version.split('.')
    const schema = extension.jsonSchema as any
    const properties = schema?.properties?.properties?.properties ?? {}
    const requiredProperties = schema?.properties?.properties?.required ?? []

    await extensionSchema.validate(schema, {strict: true})

    const collectionProperties = Object.keys(properties).map(k => {
      const [_, fieldName] = k?.split(':')
      const property = properties[k]
      const {type: fieldType, ...fieldProperties} = property
      const fieldRequired = requiredProperties.includes(k)

      if (fieldProperties && Object.keys(fieldProperties).length) {
        const fields = Object.keys(fieldProperties)
        throw new Error(
          `Unsupported property field${fields.length > 1 ? 's' : ''}: ${fields.join(', ')}`
        )
      }

      return {fieldName, fieldType, fieldRequired, fieldProperties}
    })

    return {
      title: extension.title,
      description: extension.description,
      major: versions?.[0],
      minor: versions?.[1],
      patch: versions?.[2],
      collectionProperties,
      prefix: Object.keys(properties)?.[0]?.split(':')?.[0],
    }
  }

  const onClickEditProject = async (id: string) => {
    const project = await imagineSdk.fetchProject({id, useCache: false})
    setCurrentProjectEdit(project)
    const initialValues = await mapProjectToFormFields(project)
    setProjectInitialValues(initialValues)
    setShowCreateProjectDialog(true)
  }

  const onClickEditCollection = async (id: string) => {
    const collection = await imagineSdk.fetchCollection({id})
    setCurrentCollectionEdit(collection)
    const initialValues = await mapCollectionToFormFields(collection)
    setCollectionInitialValues(initialValues)
    setShowCreateCollectionDialog(true)
  }

  const onClickEditExtension = async (id: string, version: string) => {
    const extension = await imagineSdk.fetchStacExtension({id, version})
    let initialValues: CreateExtensionFields
    try {
      initialValues = await mapExtensionToFormFields(extension)
    } catch (err) {
      console.error(err)
      console.error(err.message)
      handleCloseExtensionForm()
      errorNotification('Self service does not support extension edits with complex property types')
      return
    }
    setCurrentExtensionEdit(extension)
    setExtensionInitialValues(initialValues)
    setShowCreateExtensionDialog(true)
  }

  const onClickEdit = async ({id, version}: {id: string; version?: string}) => {
    switch (contentType) {
      case 'projects':
        await onClickEditProject(id)
        break
      case 'collections':
        await onClickEditCollection(id)
        break
      case 'extensions':
        await onClickEditExtension(id, version)
        break
    }
  }

  const onClickCreate = () => {
    switch (contentType) {
      case 'projects':
        handleClickCreateProject()
        break
      case 'collections':
        handleClickCreateCollection()
        break
      case 'extensions':
        handleClickCreateExtension()
        break
    }
  }

  const handleClickCreateCollection = () => {
    setShowCreateCollectionDialog(true)
  }

  const handleCloseCollectionForm = () => {
    setShowCreateCollectionDialog(false)
    setCollectionInitialValues(undefined)
    setCurrentCollectionEdit(null)
  }

  const handleClickCreateExtension = () => {
    setShowCreateExtensionDialog(true)
  }

  const handleCloseExtensionForm = () => {
    setShowCreateExtensionDialog(false)
    setExtensionInitialValues(undefined)
    setCurrentExtensionEdit(null)
  }

  const handleClickCreateProject = () => {
    setShowCreateProjectDialog(true)
  }

  const handleCloseProjectForm = () => {
    setShowCreateProjectDialog(false)
    setProjectInitialValues(undefined)
    setCurrentProjectEdit(null)
  }

  const handleCollectionSubmit = async (values: CreateCollectionFields) => {
    const options = {
      id: values.name.toLowerCase().replace(/ /g, '-'),
      title: values.name,
      description: values.description,
      itemStacExtensions: await Promise.all(
        values.extensions?.map(ext =>
          imagineSdk.fetchStacExtension({id: ext.value, version: ext.version})
        )
      ),
      entitlements: {
        admin: {
          appIds: values.appEntitlements.admin.map(group => group.id),
          papiGroups: values.admin.map(group => group.id),
        },
        delete: {
          appIds: values.appEntitlements.delete.map(group => group.id),
          papiGroups: values.delete.map(group => group.id),
        },
        publish: {
          appIds: values.appEntitlements.publish.map(group => group.id),
          papiGroups: values.publish.map(group => group.id),
        },
        download: {
          appIds: values.appEntitlements.download.map(group => group.id),
          papiGroups: values.download.map(group => group.id),
        },
      },
      keywords: values.keywords,
      license: values.license,
      providers: values.providers,
    }
    if (currentCollectionEdit) {
      try {
        await currentCollectionEdit.updateMetadata(options)
        successNotification('Successfully updated collection')
        handleCloseCollectionForm()
      } catch (err) {
        const details = 'Failed to update collection'
        handleSentryError(err, details)
        errorNotification(details)
      }
    } else {
      try {
        const collection = await imagineSdk.createCollection(options)
        successNotification('Successfully created collection')
        handleCloseCollectionForm()
        try {
          await onAddData(collection)
        } catch (err) {
          const details = 'Failed to update collection directory page with new collection'
          handleSentryError(err, details)
        }
      } catch (err) {
        const details = 'Failed to create collection'
        handleSentryError(err, details)
        errorNotification(details)
      }
    }
  }

  const handleExtSubmit = async (values: CreateExtensionFields) => {
    const options = {
      prefix: values.prefix,
      title: values.title,
      description: values.description,
      version: `${values.major}.${values.minor}.${values.patch}`,
      properties: values.collectionProperties.map(p => ({
        propertyName: p.fieldName,
        propertyType: p.fieldType,
      })),
      required: values.collectionProperties.filter(p => p.fieldRequired).map(p => p.fieldName),
    }

    if (currentExtensionEdit) {
      try {
        await currentExtensionEdit.updateMetadata(options)
        successNotification('Successfully updated extension')
        handleCloseExtensionForm()
      } catch (err) {
        const details = 'Failed to update extension'
        handleSentryError(err, details)
        errorNotification(details)
      }
    } else {
      try {
        const extension = await imagineSdk.createStacExtension(options)
        setRecentCreatedExtensions(recentCreatedExtensions.concat(options))
        successNotification('Successfully created extension')
        handleCloseExtensionForm()
        try {
          await onAddData(extension)
        } catch (err) {
          const details = 'Failed to update extension directory page with new extension'
          handleSentryError(err, details)
        }
      } catch (err) {
        const details = 'Failed to create extension'
        errorNotification(details)
        handleSentryError(err, details)
      }
    }
  }

  const handleProjectSubmit = async (values: CreateProjectFields) => {
    const options = {
      id: values.title.toLowerCase().replace(/ /g, '-'),
      title: values.title,
      entitlements: {
        admin: {
          appIds: values.appEntitlements.admin.map(group => group.id),
          papiGroups: values.admin.map(group => group.id),
        },
        delete: {
          appIds: values.appEntitlements.delete.map(group => group.id),
          papiGroups: values.delete.map(group => group.id),
        },
        publish: {
          appIds: values.appEntitlements.publish.map(group => group.id),
          papiGroups: values.publish.map(group => group.id),
        },
        download: {
          appIds: values.appEntitlements.download.map(group => group.id),
          papiGroups: values.download.map(group => group.id),
        },
      },
      defaultRegion: values.region,
    }
    if (currentProjectEdit) {
      try {
        await currentProjectEdit.updateMetadata(options)
        successNotification('Successfully updated project')
        handleCloseProjectForm()
      } catch (err) {
        const details = 'Failed to update projec'
        errorNotification(details)
        handleSentryError(err, details)
      }
    } else {
      try {
        const project = await imagineSdk.createProject(options)
        try {
          await onAddData(project)
        } catch (err) {
          const details = 'Failed to update project directory page with new project'
          handleSentryError(err, details)
        }
        successNotification('Successfully created project')
        handleCloseProjectForm()
      } catch (err) {
        const details = 'Failed to create project'
        errorNotification(details)
        handleSentryError(err, details)
      }
    }
  }

  useEffect(() => {
    getPinnedCollections().then(pinnedCollections => {
      setUserPinnedCollections(pinnedCollections)
    })
  }, [])

  // Clear memory to reset page of last project/collection/etc user was last on
  useEffect(() => {
    localStorage.removeItem('projectName')
    localStorage.removeItem('projectPage')
    localStorage.removeItem('collectionName')
    localStorage.removeItem('collectionPage')
    localStorage.removeItem('albumName')
    localStorage.removeItem('albumPage')
    localStorage.removeItem('searchTermString')
    localStorage.removeItem('searchTermPage')
    localStorage.removeItem('advancedSearchTerm')
    localStorage.removeItem('advancedSearchPage')
  }, [])

  if (error)
    return (
      <div className="notification-container">
        <CardGenericError
          title="There was a problem connecting to Imagine"
          description="Please try again. If this issue continues, contact Location360 Support."
          caption={error ?? 'Initial content load failure'}
        />
      </div>
    )

  const nonEmptyContent = useMemo(() => {
    return filteredContent?.filter(i => i.count)
  }, [filteredContent])

  const showEmptyContent = event => {
    setHideEmptyContent(event.target.checked)
  }

  const labelForHideEmptyContentToggle = hideEmptyContent
    ? `Hide empty ${contentType} - ${
        filteredContent.length - nonEmptyContent.length
      } ${contentType} hidden`
    : `Hide empty ${contentType}`

  return (
    <>
      <MainDisplay>
        {loading ? (
          <LoadingSpinner message="Loading Imagine UI" />
        ) : (
          <>
            {filteredContent && (
              <>
                <div className="filterd-projects-count">
                  <TextIndicatorDirectoryLoadState
                    loading={!!populating}
                    count={
                      populating
                        ? {current: numPossibleElements, total: maxCount}
                        : {
                            current: hideEmptyContent
                              ? nonEmptyContent.length
                              : filteredContent?.length,
                            total: numPossibleElements ?? maxCount,
                          }
                    }
                    contentType={contentType}
                  />
                  {(contentType === 'collections' || contentType === 'albums') && (
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch checked={hideEmptyContent} onChange={showEmptyContent} />}
                        label={labelForHideEmptyContentToggle}
                      />
                    </FormGroup>
                  )}
                </div>
              </>
            )}
            <DirectoryGridContent
              filteredContent={hideEmptyContent ? nonEmptyContent : filteredContent}
              contentType={contentType}
              populating={populating}
              userPinnedCollections={userPinnedCollections}
              onClickEdit={onClickEdit}
            />
          </>
        )}
      </MainDisplay>

      <DirectoryFooter>
        <div className="toggle">
          <Link to="/directory/projects">
            <ToggleButton
              variant="contained"
              disableElevation={contentType === 'projects'}
              disabled={loading}
            >
              Projects
            </ToggleButton>
          </Link>
        </div>
        <div className="toggle">
          <Link to="/directory/collections">
            <ToggleButton
              variant="contained"
              disableElevation={contentType === 'collections'}
              disabled={loading}
            >
              Collections
            </ToggleButton>
          </Link>
        </div>

        <div className="toggle">
          <Link to="/directory/albums">
            <ToggleButton
              variant="contained"
              disableElevation={contentType === 'albums'}
              disabled={loading}
            >
              Albums
            </ToggleButton>
          </Link>
        </div>

        {canEditExtensions && (
          <div className="toggle">
            <Link to="/directory/extensions">
              <ToggleButton
                variant="contained"
                disableElevation={contentType === 'extensions'}
                disabled={loading}
              >
                Extensions
              </ToggleButton>
            </Link>
          </div>
        )}

        <DirectoryGridFilter />
        <img src={loc360Logo} alt="Location 360 Imagine UI" height="55" />
        {contentType === 'albums' ? (
          <ButtonCreateNewAlbum />
        ) : (
          <Fab color="primary" aria-label="add" sx={{marginRight: '15px'}} onClick={onClickCreate}>
            <AddIcon />
          </Fab>
        )}
      </DirectoryFooter>
      <CollectionFormDialog
        open={showCreateCollectionDialog}
        onClose={handleCloseCollectionForm}
        recentExtensions={recentCreatedExtensions}
        onSubmit={handleCollectionSubmit}
        initialValues={collectionInitialiValues}
      />
      <ExtensionFormDialog
        open={showCreateExtensionDialog}
        onClose={handleCloseExtensionForm}
        onSubmit={handleExtSubmit}
        initialValues={extensionInitialValues}
        validate={extensionValidator}
      />
      <ProjectFormDialog
        open={showCreateProjectDialog}
        onClose={handleCloseProjectForm}
        onSubmit={handleProjectSubmit}
        initialValues={projectInitialiValues}
      />
    </>
  )
}

export default DirectoryLayout
