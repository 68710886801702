import React, {useContext} from 'react'
import {Button, DialogTitle} from '@mui/material'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {useFormikContext} from 'formik'
import {GroupEntitlementFields} from './GroupEntitlementFields'
import {AppEntitlementFields} from './AppEntitlementFields'
import {
  CreateProjectFields,
  TitleSection,
  Container,
  StyledTextField,
  DialogFooter,
} from './SelfService'
import {FormikErrorMessage} from './FormikErrorMessage'
import {RegionSelect} from './RegionSelect'

export function ProjectForm({onClose}: {onClose: () => void}) {
  const {values, handleChange, handleBlur, isSubmitting, isValid, touched, handleSubmit} =
    useFormikContext<CreateProjectFields>()
  const {loading, error} = useContext(imagineServiceContext)

  return (
    <form onSubmit={handleSubmit} role="form">
      <TitleSection>
        <DialogTitle>Project Editor</DialogTitle>
      </TitleSection>
      <Container>
        <Container>
          <StyledTextField
            label="Project Title"
            name="title"
            value={values.title}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
          <FormikErrorMessage name="title" />
          <GroupEntitlementFields onClose={onClose} />
          <AppEntitlementFields />
          <RegionSelect style={{marginTop: '20px'}} />
        </Container>
        <DialogFooter>
          <Button size="large" onClick={onClose}>
            Close
          </Button>
          <Button
            type="submit"
            disabled={
              isSubmitting || loading || !!error || !isValid || !Object.keys(touched).length
            }
            sx={{marginLeft: '10px'}}
            variant="contained"
            size="large"
          >
            Submit
          </Button>
        </DialogFooter>
      </Container>
    </form>
  )
}
