export const isProd = window?.location.host.includes('velocity.ag')
export const isLocal = process.env.__LOCAL

console.log(`The deploy environment is set to ${isProd ? '' : 'non-'}production`)

export const productionBindings = {
  localDevelopment: false,
  environment: 'Prod',
  serviceBindings: {
    'velocity-home': 'velocity.ag',
    'phoenix-home': 'phoenix-tools.io',
    'home-url': 'https://phoenix-tools.io/home',
    'preferences-url': 'https://preferences.phoenix-tools.io',
    'messaging-url': 'https://messaging.phoenix-tools.io',
    'navigation-url': 'https://navigation.phoenix-tools.io',
    'profile-url': 'https://profile.phoenix-tools.io',
    'user-feedback-url': 'https://user-feedback.phoenix-tools.io',
    imagine_cookie: 'imagine',
    connect_project_name: 'imagine',
  },
  buildDate: 'July 7',
}

export const nonProductionBindings = {
  localDevelopment: false,
  environment: 'NonProd',
  serviceBindings: {
    'velocity-home': 'velocity-np.ag',
    'phoenix-home': 'phoenix-tools-np.io',
    'home-url': 'https://phoenix-tools-np.io/home',
    'preferences-url': 'https://preferences.phoenix-tools-np.io',
    'messaging-url': 'https://messaging.phoenix-tools-np.io',
    'navigation-url': 'https://navigation.phoenix-tools-np.io',
    'profile-url': 'https://profile.phoenix-tools-np.io',
    'user-feedback-url': 'https://user-feedback.phoenix-tools-np.io',
    imagine_cookie: 'imagine-np',
    connect_project_name: 'imaginetest',
  },
  buildDate: 'July 7',
}

export const bindings = isProd ? productionBindings : nonProductionBindings
window.phoenix = bindings
