import React, {ReactNode, createContext, useEffect, useState} from 'react'
import {getBearerToken} from '../utilities/initializeProfile'
import {isProd} from '../utilities/serviceBindings'
import {handleSentryError} from '../utilities/sentryErrors'
import type {
  ImagineSDK,
  Project,
  Collection,
  SessionInfo,
  Album,
  EntitlementLevel as EntitlementLevelType,
  FilterComparisonClause as FilterComparisonClauseType,
  FilterConjunctionClause as FilterConjunctionClauseType,
  FilterConjunctionOperator as FilterConjunctionOperatorType,
  StacExtension,
} from '@bayer-int/imagine-sdk-browser'
import {fetchImagineSdk} from '../sdk/imagineSdk'

const imagineServiceContext = createContext<ImagineServiceContext>({} as ImagineServiceContext)
const {Provider} = imagineServiceContext
let EntitlementLevel: typeof EntitlementLevelType
let FilterComparisonClause: typeof FilterComparisonClauseType
let FilterConjunctionClause: typeof FilterConjunctionClauseType
let FilterConjunctionOperator: typeof FilterConjunctionOperatorType

/**
 * Item metadata store for the Item page
 * Provides a store to hone down on prop drilling
 * */
function ImagineServiceProvider({children}: {children: ReactNode}) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [imagineSdk, setImagineSdk] = useState<ImagineSDK | null>(null)

  // Load SDK on initial load
  useEffect(() => {
    console.log('Is prod ? ', isProd)
    const loadSdk = async () => {
      try {
        const {
          EntitlementLevel: entitlementLevel,
          Environment,
          HeaderAuth,
          ImagineSDK,
          FilterComparisonClause: comparisonClause,
          FilterConjunctionClause: conjunctionClause,
          FilterConjunctionOperator: conjunctionOperator,
        } = await fetchImagineSdk()

        const sdk = new ImagineSDK({
          auth: new HeaderAuth({
            headerFunction: async () => `Bearer ${await getBearerToken()}`,
          }),
          environment: isProd ? Environment.PRODUCTION : Environment.NON_PRODUCTION,
          _validationEnabled: true,
          downloadOptions: {
            cacheThumbnails: true,
          },
          systemOptions: {
            maxMemoryUsage: Infinity,
          },
        })

        setImagineSdk(sdk)
        setLoading(false)
        EntitlementLevel = entitlementLevel
        FilterComparisonClause = comparisonClause
        FilterConjunctionClause = conjunctionClause
        FilterConjunctionOperator = conjunctionOperator
      } catch (e) {
        const additionalErrorDetails = 'Unable to load imagine SDK.'
        handleSentryError(e, additionalErrorDetails)
        setError(e)
      }
    }
    loadSdk()
  }, [])

  async function retrieveProjects() {
    if (!imagineSdk || error) throw new Error('Imagine SDK not loaded')
    const projects: Project[] = []
    for await (const project of imagineSdk.fetchProjects()) {
      projects.push(project)
    }
    return projects
  }

  async function retrieveCollections() {
    if (!imagineSdk || error) throw new Error('Imagine SDK not loaded')
    const collections: Collection[] = []
    for await (const collection of imagineSdk.fetchCollections()) {
      collections.push(collection)
    }
    return collections
  }

  async function retrieveAlbums() {
    if (!imagineSdk || error) throw new Error('Imagine SDK not loaded')
    const albums: Album[] = []
    for await (const album of imagineSdk.fetchAlbums()) {
      albums.push(album)
    }
    return albums
  }

  async function retrieveStacExtensions() {
    if (!imagineSdk || error) throw new Error('Imagine SDK not loaded')
    const extensions: StacExtension[] = []
    const iterator = imagineSdk.fetchStacExtensions()
    while (true) {
      try {
        const res = await iterator.next()
        if (res.value?.title) {
          extensions.push(res.value)
        }
        if (res.done) {
          break
        }
      } catch (err) {
        console.error(err)
      }
    }
    return extensions
  }

  const retrieveSession = async (): Promise<SessionInfo> => {
    if (!imagineSdk || error) throw new Error('Imagine SDK not loaded')
    return imagineSdk.sessionInfo()
  }

  const contextValue = {
    error,
    loading,
    imagineSdk,
    retrieveAlbums,
    retrieveCollections,
    retrieveProjects,
    retrieveSession,
    retrieveStacExtensions,
  }

  return <Provider value={contextValue}>{children}</Provider>
}

export interface ImagineServiceContext {
  error: Error | null
  loading: boolean
  imagineSdk: ImagineSDK | null
  retrieveAlbums: () => Promise<Album[]>
  retrieveCollections: () => Promise<Collection[]>
  retrieveProjects: () => Promise<Project[]>
  retrieveSession: () => Promise<SessionInfo>
  retrieveStacExtensions: () => Promise<StacExtension[]>
}

export {
  EntitlementLevel,
  FilterComparisonClause,
  FilterConjunctionClause,
  FilterConjunctionOperator,
  imagineServiceContext,
  ImagineServiceProvider,
}
