import React from 'react'
import App from './App'
import {navbarInstalled} from './utilities/initializeProfile'
import * as Sentry from '@sentry/browser'
import {ExtraErrorData} from '@sentry/integrations'
import {BrowserTracing} from '@sentry/tracing'
import {isLocal, isProd} from './utilities/serviceBindings'
import {createRoot} from 'react-dom/client'

import './style.css'
import {setupInterceptor} from './utilities/fetchInterceptor'
import {setupCache} from './utilities/cache'

setupInterceptor()
setupCache()

let environment
if (isProd) {
  environment = 'production'
} else if (isLocal) {
  environment = 'local'
} else {
  environment = 'development'
}

Sentry.init({
  dsn: 'https://5cada471664441a78c3a1ef406471dcb@o1128229.ingest.sentry.io/4503976835612672',
  integrations: [new BrowserTracing(), new ExtraErrorData()],
  environment,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const container = document.querySelector('.contents')
const root = createRoot(container)

if (!process.env.TESTING) {
  navbarInstalled
    .then(() => {
      root.render(<App />)
    })
    .catch(e => {
      console.error(`App initial render fails `, e)
    })
}
