import React, {useEffect, useState} from 'react'
import {saveAs} from 'file-saver'
import formatBytes from 'pretty-bytes'
import {duration} from 'moment'
import LinearProgress from '@mui/material/LinearProgress'
import {red} from '@mui/material/colors'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import styled, {css} from 'styled-components'

import CardGenericError from './CardGenericError'
import {handleSentryError} from '../utilities/sentryErrors'

const UploaderContainer = styled.section`
  display: grid;

  height: 550px;

  grid-template-rows: 20px 50px 110px 1fr;
  grid-row-gap: 15px;

  .row-status-bar,
  .row-message-area {
    margin: 0 auto;
    min-width: 0;
    width: 90%;
  }

  .row-status-bar {
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    grid-template-rows: 40px 20px 40px;

    .indicator-zero {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      justify-self: start;
      align-self: end;
      align-self: end;
      border-left: 1px solid #222;
      position: relative;
      padding: 0 0 4px 4px;
      top: 9px;

      & > .MuiTypography-caption {
        display: block;
        line-height: unset;
      }
    }

    .indicator-total {
      justify-self: end;
      align-self: end;
      grid-row: 1 / 2;
      grid-column: 3 / 4;
      border-right: 1px solid #222;
      position: relative;
      top: 9px;
      left: -8px;
      padding: 0 4px 4px 0;

      & > .MuiTypography-caption {
        display: block;
        line-height: unset;
        text-align: right;
      }
    }

    .progress-bar {
      align-self: start;
      padding-top: 7px;
      grid-row: 2 / 3;
      grid-column: 1 / 4;
      align-self: start;
      padding-top: 7px;
    }

    .progress-indicator {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      text-align: center;
    }
  }

  ${props =>
    props.fatalError &&
    css`
      /* Controls main color of bar */
      .MuiLinearProgress-barColorPrimary {
        background-color: ${red[600]};
      }

      /* Controls secondary color of bar */
      .MuiLinearProgress-colorPrimary {
        background-color: ${red[200]};
      }
    `}
`

const Path = styled('pre')`
  background-color: #f0f0f0;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  max-width: 100%;
  overflow-x: auto;
  padding: 0.5em;
`

let upload

export default function UploaderMissionPackageUploadingPane(props) {
  const {uploader} = props
  const [fileErrors, setFileErrors] = useState([])
  const [fatalError, setFatalError] = useState(false)
  const [uploaderComplete, setUploaderComplete] = useState(false)
  const [progress, setProgress] = useState({
    bytesDone: 0,
    bytesTotal: 0,
    filesDone: 0,
    filesTotal: 0,
    message: '',
    rate: 0,
    timeRemaining: 0,
  })
  const [targetPath, setTargetPath] = useState('')

  useEffect(() => {
    if (!uploader) return

    upload = uploader.upload(update => {
      const {error, message, status} = update

      if (error) {
        handleSentryError(error)
      }

      setProgress(update)

      if (status) {
        switch (status) {
          case 'error-file':
            setFileErrors([...fileErrors, message])
            break
          case 'complete-partial':
          case 'error-fatal':
            setFatalError(true)
            break
          case 'complete':
            setUploaderComplete(true)
            break
          case 'ongoing':
          case 'evaluating':
          case 'suspended':
            break
          default:
            setFatalError(true)
            throw new Error(`Unknown mission package uploader update statement: "${status}"`)
        }
      } else {
        if (bytesDone === bytesTotal) {
          setUploaderComplete(true)
        }
      }
    })

    setTargetPath(upload.targetPath)

    // Suspend upload if component unmounted during upload.
    return () => {
      if (upload.status === 'ongoing') {
        upload.suspend()
      }
    }
  }, [uploader])

  const {bytesDone, bytesTotal, filesDone, filesTotal, message, rate, timeRemaining} = progress
  const totalBytesFormatted = formatBytes(bytesTotal)
  const bytesDoneFormatted = formatBytes(bytesDone)

  const missionKey = uploader?.json?.mission_data?.mission_key

  const percentage = Math.min(bytesTotal ? Math.floor((bytesDone / bytesTotal) * 100) : 0, 100)
  let timeRemainingFormatted = 'Calculating time remaining...'
  if (Number.isFinite(timeRemaining)) {
    const timeFormatted = duration(Math.max(60e3, timeRemaining)).humanize()
    const rateFormatted = formatBytes(rate * 8, {bits: true})
    timeRemainingFormatted = `about ${timeFormatted} remaining (${rateFormatted}/s)`
  }

  if (fatalError && fileErrors.length > 0) {
    saveAs(
      new Blob([JSON.stringify(fileErrors)], {type: 'text/plain;charset=utf-8'}),
      `upload-file-errors-${Date.now()}.log`
    )
  }

  return (
    <UploaderContainer fatalError={fatalError}>
      <div className="spacer" />
      <Box display="flex" justifyContent="space-evenly" justifySelf="center">
        {uploaderComplete && (
          <Typography variant="h6">Finished uploading mission {missionKey}</Typography>
        )}
        {!(fatalError || uploaderComplete) && (
          <Typography variant="h6">Uploading mission {missionKey}</Typography>
        )}
      </Box>
      <div className="row-status-bar">
        <div className="indicator-zero">
          <Typography variant="caption"> {filesDone} files</Typography>
          <Typography variant="caption"> {bytesDoneFormatted}</Typography>
        </div>
        <div className="indicator-total">
          <Typography variant="caption"> {filesTotal} files</Typography>
          <Typography variant="caption"> {totalBytesFormatted}</Typography>
        </div>

        <Box display="flex" alignItems="center" className="progress-bar">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={percentage} color="primary" />
          </Box>
        </Box>
        {!(fatalError || uploaderComplete) && (
          <Typography className="progress-indicator" variant="body2">
            {percentage}% – {timeRemainingFormatted}
          </Typography>
        )}
      </div>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className="row-message-area"
        style={{marginBottom: '20px'}}
      >
        {fatalError && (
          <CardGenericError noMargin title="Upload Error" description={message} level="error" />
        )}
        {uploaderComplete && (
          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            <Typography variant="body1" style={{textAlign: 'center', flexGrow: 1}}>
              {message}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" maxWidth="100%">
              <Typography variant="overline">Mission Location</Typography>
              <Path>{targetPath}</Path>
            </Box>
          </div>
        )}
        {!(fatalError || uploaderComplete) && (
          <Typography variant="body2">
            Your mission package is now uploading. Please do not refresh the page or close the
            browser.
          </Typography>
        )}
      </Box>
    </UploaderContainer>
  )
}
