import React, {useEffect, useContext, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Button, Paper, Typography} from '@mui/material'
import {
  Info as InfoIcon,
  History as HistoryIcon,
  PushPinOutlined as PushPinOutlinedIcon,
  PushPin as PushPinIcon,
} from '@mui/icons-material'
import mapSearchParametersToFetchItemsOptions from '../utilities/advancedSearchParameters'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {handleSentryError} from '../utilities/sentryErrors'

import useCollectionPin from '../hooks/useCollectionPin'
import groupingItemCountCopy from '../helpers/groupingItemCountCopy'
import GallerySummaryCollectionTimeline from './GallerySummaryCollectionTimeline'
import GallerySummaryCollectionEditTitleButton from './GallerySummaryCollectionEditTitleButton'
import GallerySummaryCollectionEditDescButton from './GallerySummaryCollectionEditDescButton'
import GallerySummaryCollectionDeleteButton from './GallerySummaryCollectionDeleteButton'

const SummaryContainer = styled(Paper)`
  padding: 5px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-column-gap: 15px;

  .infoIcon {
    display: flex;
    color: var(--mdc-theme-primary);
    justify-self: center;
    align-self: center;
  }

  .editIconWrapper {
    marginleft: 5px;
    display: inline-flex;
  }

  .editIcon {
    width: 20px;
  }

  .titleBlock {
    display: flex;
    justify-content: space-between;
  }

  .textWrapper {
    display: inline-flex;
    align-items: center;
  }

  .description {
    color: ${props => props.theme?.palette?.subduedText};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .action {
    grid-column: 2/ 3;
    justify-content: flex-end;
    display: flex;
  }

  button {
    width: 280px;
  }

  p {
    margin-right: 5px;
  }
`

const TimelineContainer = styled(Paper)`
  padding: 5px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-column-gap: 15px;

  .icon {
    display: flex;
    color: var(--mdc-theme-primary);
    justify-self: center;
    align-self: flex-start;
  }

  .toggleTimeline {
    color: var(--mdc-theme-primary);
    cursor: pointer;
  }
`

/**
 * The summary of results for a given collection
 *
 */
function GallerySummaryCollection(props) {
  const {description, numberItemsInCollection, name, openSchemaDialog, collection} = props
  const {imagineSdk} = useContext(imagineServiceContext)
  const [lastUpdatedDate, setLastUpdatedDate] = useState('')
  const [showTimeline, setShowTimeline] = useState(false)
  const [timelineData, setTimelineData] = useState([])
  const [reasonTimelineLimitedData, setReasonTimelineLimitedData] = useState('')
  const [isPinned, setIsPinned] = useState(false)
  const [pinnedCollections, setPinnedCollections] = useState([])
  const [hasDeletePermission, setHasDeletePermission] = useState(false)
  const [hasPublishPermission, setHasPublishPermission] = useState(false)

  const {getPinnedCollections, addToPinnedCollections, removeFromPinnedCollections} =
    useCollectionPin()

  useEffect(() => {
    getPinnedCollections().then(pinnedCollections => {
      setIsPinned(pinnedCollections.includes(name))
      setPinnedCollections(pinnedCollections)
    })
  }, [])

  useEffect(() => {
    async function getEntitlements() {
      const entitlements = await collection.entitlements()
      if (entitlements.currentUserHasEntitlement('DELETE')) {
        setHasDeletePermission(true)
      }
      if (entitlements.currentUserHasEntitlement('PUBLISH')) {
        setHasPublishPermission(true)
      }
    }

    getEntitlements()
  }, [collection])

  const handlePinButton = () => {
    if (isPinned) {
      removeFromPinnedCollections(pinnedCollections, name)
      setIsPinned(false)
    } else {
      addToPinnedCollections(pinnedCollections, name)
      setIsPinned(true)
    }
  }

  // Retrieves oldest and newest collection information to build timeline
  // The newest and oldest dates for a collection are obtained by making calls directly to API (bypassing SDK)
  useEffect(() => {
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })

    const dateFormatterMonthAndYear = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
    })

    const getNewestUpdatedDate = async collection => {
      const itemsIterator = await collection.fetchItems({
        limit: 1,
        sortBy: [
          {
            direction: 'desc',
            field: 'properties.datetime',
          },
        ],
      })

      for await (const item of itemsIterator) {
        let newestDate = new Date(item.properties.datetime)
        // If date of newest item is in the future or does not exist, set to today's date
        if (!(newestDate instanceof Date && isFinite(newestDate)) || newestDate > Date.now()) {
          newestDate = new Date(Date.now())
        } else {
          // Only set lastUpdatedDate if date of newest item is in the past AND exists
          setLastUpdatedDate(dateFormatter.format(newestDate))
        }
        return newestDate
      }
    }

    const getOldestUpdatedDate = async collection => {
      const itemsIterator = await collection.fetchItems({
        limit: 1,
        sortBy: [
          {
            direction: 'asc',
            field: 'properties.datetime',
          },
        ],
      })

      for await (const item of itemsIterator) {
        let oldestDate = new Date(item.properties.datetime)
        // If date of oldest item does not exist or is older than 10 years, set oldestDate to today from 1 decade ago
        const dateFromDecadeAgo = new Date(Date.now())
        dateFromDecadeAgo.setFullYear(dateFromDecadeAgo.getFullYear() - 10)
        if (!oldestDate) {
          oldestDate = dateFromDecadeAgo
          setReasonTimelineLimitedData(
            'The oldest item in this collection does not have a datetime. Not all items will be accounted for in the timeline.'
          )
        } else if (oldestDate < dateFromDecadeAgo) {
          oldestDate = dateFromDecadeAgo
          setReasonTimelineLimitedData(
            'The oldest item in this collection is older than 10 years. Not all items will be accounted for in the timeline.'
          )
        }
        return oldestDate
      }
    }

    async function getTimelineData() {
      const data = []
      const newestItemDate = await getNewestUpdatedDate(collection)
      const oldestItemDate = await getOldestUpdatedDate(collection)
      if (!newestItemDate && !oldestItemDate) return
      const monthsInBetween =
        newestItemDate.getMonth() -
        oldestItemDate.getMonth() +
        12 * (newestItemDate.getFullYear() - oldestItemDate.getFullYear()) +
        1

      async function advancedSearchItemsBetweenDates(searchAttributes) {
        let sdkCompatibleSearchTerms
        try {
          sdkCompatibleSearchTerms = mapSearchParametersToFetchItemsOptions(searchAttributes)
        } catch (e) {
          const additionalErrorDetails = `There was an error creating sdk compatible search terms ${JSON.stringify(
            searchAttributes
          )}.`
          handleSentryError(e, additionalErrorDetails)
        }

        let fetchItemsOptions
        try {
          const fetchItemsOptions = {
            collections: sdkCompatibleSearchTerms.collections,
            acquiredAt: {
              start: new Date(sdkCompatibleSearchTerms.acquiredAt.start),
              end: new Date(sdkCompatibleSearchTerms.acquiredAt.end),
            },
          }
          const result = await imagineSdk.fetchItems(fetchItemsOptions)
          data.push({
            date: dateFormatterMonthAndYear.format(searchAttributes.fromDate),
            added: await result.count(),
          })
        } catch (e) {
          const additionalErrorDetails = `There was an error fetching items with the following options: ${JSON.stringify(
            fetchItemsOptions
          )}.`
          handleSentryError(e, additionalErrorDetails)
        }
      }

      for (let i = 0; i < monthsInBetween; i++) {
        const baselineDateTime = new Date(oldestItemDate)
        baselineDateTime.setFullYear(oldestItemDate.getFullYear())
        baselineDateTime.setDate(1)
        baselineDateTime.setHours(0, 0, 0)
        baselineDateTime.setMonth(oldestItemDate.getMonth() + i)
        const oneMonthAfterBaseline = new Date(baselineDateTime)
        oneMonthAfterBaseline.setMonth(baselineDateTime.getMonth() + 1)

        const searchAttributes = {
          collections: [name],
          fromDate: baselineDateTime,
          toDate: oneMonthAfterBaseline,
        }

        advancedSearchItemsBetweenDates(searchAttributes)
      }
      setTimelineData(data)
    }

    getTimelineData()
  }, [])

  return (
    <>
      <SummaryContainer elevation={0}>
        <div className="infoIcon">
          <InfoIcon />
        </div>
        <div>
          <div className="titleBlock">
            <div className="textWrapper">
              <Typography variant="body1">
                {groupingItemCountCopy({
                  grouping: 'collection',
                  name,
                  count: numberItemsInCollection,
                })}
              </Typography>
              {hasPublishPermission && (
                <GallerySummaryCollectionEditTitleButton name={name} collection={collection} />
              )}
            </div>
            {hasDeletePermission && (
              <GallerySummaryCollectionDeleteButton name={name} collection={collection} />
            )}
          </div>

          <div className="description">
            <div className="textWrapper">
              <Typography variant="body2">{description}</Typography>
              {hasPublishPermission && (
                <GallerySummaryCollectionEditDescButton
                  description={description}
                  collection={collection}
                />
              )}
            </div>
            <Button
              color="primary"
              onClick={handlePinButton}
              endIcon={isPinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
            >
              {isPinned ? 'Unpin Collection' : 'Pin Collection'}
            </Button>
          </div>
          <div>
            <div className="action">
              <Button color="primary" onClick={openSchemaDialog}>
                View and Select Schema
              </Button>
            </div>
          </div>
        </div>
      </SummaryContainer>
      <TimelineContainer elevation={0}>
        <div className="icon">
          <HistoryIcon />
        </div>
        <div>
          <Typography variant="body5">
            <span className="toggleTimeline" onClick={() => setShowTimeline(!showTimeline)}>
              {!showTimeline ? 'Show timeline ' : 'Hide timeline '}
            </span>
            for "{name}". {lastUpdatedDate && `Last updated on ${lastUpdatedDate}`}
          </Typography>
          {showTimeline && (
            <GallerySummaryCollectionTimeline
              data={timelineData}
              message={reasonTimelineLimitedData}
            />
          )}
        </div>
      </TimelineContainer>
    </>
  )
}

GallerySummaryCollection.propTypes = {
  /** The description of the collection */
  description: PropTypes.string,
  /** The name of this collection */
  name: PropTypes.string.isRequired,
  /** Lists the number of items for this collection */
  numberItemsInCollection: PropTypes.number.isRequired,
  /** Opens the schema dialog */
  openSchemaDialog: PropTypes.func.isRequired,
  /** The collection instance */
  collection: PropTypes.object.isRequired,
}

GallerySummaryCollection.defaultProps = {
  description: '',
}

export default GallerySummaryCollection
